








import Vue from "vue";
export default Vue.extend({
  props: {
    block: { type: Boolean, default: false }
  }
});
