






































import Vue from "vue";
import Header from "@/views/Header.vue";
import SignInBtn from "@/components/SignInBtn.vue";

import Footer from "@/components/desktop/Footer.vue";
import { GoogleSignedUser } from "@/types";

export default Vue.extend({
  name: "about",
  components: {
    "header-top": Header,
    "signin-btn": SignInBtn,
    "footer-area": Footer
  },
  data() {
    return {
      isSigningIn: false,
      isSignIn: null,
      user: null,
      token: ""
    };
  },
  methods: {
    async signinGoogle() {
      try {
        this.isSigningIn = true;
        //@ts-ignore
        const googleUser = await this.$gAuth.signIn();
        const idToken = googleUser.getAuthResponse().id_token;
        const userData = (await this.$store.dispatch(
          "signIn",
          idToken
        )) as GoogleSignedUser;

        this.$store.commit("setLoggedUser", userData.user);
        this.$store.commit("setIsLogged");
        this.token = userData.token;
        console.log(this.token);
        console.log("Saving token");
        localStorage.setItem("token", this.token);
        window.history.back();
      } catch (error) {
        this.isSigningIn = false;
        console.log(error);
      }
    }
  }
});
